import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';

import { toast } from "react-toastify";

import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import api from '@clients/api.client';

import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";

import { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import { toggleGenericSuccessModal } from '@redux/utils/components/GenerateSuccessModal';
import { useHistory } from 'react-router-dom';
import { urlFriendly } from '@utils/url';
import { redirectToCompanySubdomain } from '@utils/redirect';


export const createNewCompany: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.CREATE_COMPANY,
        payload: payload
    });
    return api.post('/companies', payload).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        toast.success(response.data?.response?.message || "Successful");
        const token = localStorage.getItem("users::token");
        const refresh_token = localStorage.getItem("users::refresh_token");
        if (token) {
            redirectToCompanySubdomain({
                companyTitle: response.data?.node?.company?.title,
                token: token,
                refresh_token: refresh_token,
            })
        }
        dispatch({
            type: ActionDispatchTypes.CREATE_COMPANY_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CREATE_COMPANY_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
    })

}

export const updateCompany: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.UPDATE_COMPANY,
        payload: payload
    });
    return api.put(`/companies/${payload?.companyId}`, payload).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.UPDATE_COMPANY_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPDATE_COMPANY_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse
    })

}

export const getCurrentCompany: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.GET_CURRENT_COMPANY,
        payload: payload
    });
    return api.get(`/companies/users/current`).then(response => {
        //toggleGenericSuccessModal(dispatch, errorResponse);
        const token = localStorage.getItem("users::token");
        const refresh_token = localStorage.getItem("users::refresh_token");
        if (token) {
            redirectToCompanySubdomain({
                companyTitle: response.data?.node?.company?.title,
                token: token,
                refresh_token: refresh_token,
            })
        }
        dispatch({
            type: ActionDispatchTypes.GET_CURRENT_COMPANY_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_CURRENT_COMPANY_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}


export const getCompanies: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.GET_COMPANIES,
        payload
    })
    return api.get(`/users/${getState()?.users?.user?._id}/companies`).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_COMPANIES_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANIES_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const setCurrentCompany: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const channel = new BroadcastChannel('company_change');
    dispatch({
        type: ActionDispatchTypes.SET_CURRENT_COMPANY,
        payload
    })
    return api.post(`/users/${getState()?.users?.user?._id}/set-current-company`, payload).then((response: any) => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        channel.postMessage(response.data);
        dispatch({
            type: ActionDispatchTypes.SET_CURRENT_COMPANY_SUCCESSFUL,
            payload: response.data
        })

        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.SET_CURRENT_COMPANY_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}


export const setDefaultPipeline: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.SET_USER_DEFAULT_PIPELINE,
        payload
    })
    return api.post(`/users/${getState()?.companies?.currentCompany}/${payload?.pipelineId}/set-default-pipeline`, payload).then((response: any) => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.SET_USER_DEFAULT_PIPELINE_SUCCESSFUL,
            payload: response.data
        })
        return true;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.SET_USER_DEFAULT_PIPELINE_FAILED,
            payload
        })
        return errorResponse;
    })
}
export const setDefaultType: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.SET_USER_DEFAULT_TYPE,
        payload
    })
    return api.post(`/users/${getState()?.companies?.currentCompany}/${payload?.typeId}/set-default-type`, payload).then((response: any) => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.SET_USER_DEFAULT_TYPE_SUCCESSFUL,
            payload: response.data
        })
        return true;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.SET_USER_DEFAULT_TYPE_FAILED,
            payload
        })
        return errorResponse;
    })
}

export const getCompanyUsers: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.GET_COMPANY_USERS,
        payload
    })
    return api.get(`/companies/${getState()?.companies?.currentCompany}/users`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_USERS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_USERS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

// Without the nomination
export const getCompanyUserList: TAction = (payload: any) => (dispatch: any, getState: any) => {

    return api.get(`/companies/${getState()?.companies?.currentCompany}/user-list`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {

        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);

        return errorResponse;
    })
}


export const updateCompanyUser: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.UPDATE_COMPANY_USER,
        payload
    })
    return api.put(`/companies/${getState()?.companies?.currentCompany}/users`, payload).then((response: any) => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        toast.success(response.data?.response?.message || "Successful");
        dispatch({
            type: ActionDispatchTypes.UPDATE_COMPANY_USER_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPDATE_COMPANY_USER_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const deleteCompany: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.DELETE_COMPANY,
        payload
    })

    return api.delete(`/companies/${getState()?.companies?.company?._id}`, payload)
        .then((response: any) => {
            toast.success(response.data?.response?.message || "Successful");
            dispatch({
                type: ActionDispatchTypes.DELETE_COMPANY_SUCCESSFUL,
                payload: response.data
            })
            return response?.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toast.error(errorResponse?.message);
            //toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_COMPANY_FAILED,
                payload: errorResponse
            });
            return errorResponse;
        }
        )
}

export const deleteCompanyUser: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.DELETE_USER_FROM_COMPANY,
        payload
    })
    return api.delete(`/companies/${getState()?.companies?.currentCompany}/user`, {
        data: payload
    }).then((response: any) => {
        toast.success(response.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.DELETE_USER_FROM_COMPANY_SUCCESSFUL,
            payload: response.data
        })
        window.location.href = "/";
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_USER_FROM_COMPANY_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}


export const getCompanyUsersInvites: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.GET_COMPANY_USERS_INVITES,
        payload
    })
    return api.get(`/companies/${getState()?.companies?.currentCompany}/invites`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_USERS_INVITES_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_USERS_INVITES_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const updateCompanyUsersInvites = (payload: any) => (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.UPDATE_COMPANY_USERS_INVITES,
        payload
    })

    return api.put(`/companies/invites/${payload?._id}`, payload).then((response: any) => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        toast.success(response.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.UPDATE_COMPANY_USERS_INVITES_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.UPDATE_COMPANY_USERS_INVITES_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })

}


export const deleteCompanyUsersInvites = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.DELETE_COMPANY_USERS_INVITES,
        payload
    })
    return api.delete(`/companies/invites/${payload?._id}`).then((response: any) => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        toast.success(response.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.DELETE_COMPANY_USERS_INVITES_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.DELETE_COMPANY_USERS_INVITES_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })

}


export const getCompanyDepartments: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.GET_COMPANY_DEPARTMENTS,
        payload
    })
    return api.get(`/companies/${getState()?.companies?.currentCompany}/departments`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_DEPARTMENTS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_DEPARTMENTS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const getCompanyRoles: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.GET_COMPANY_ROLES,
        payload
    })
    return api.get(`/companies/${getState()?.companies?.currentCompany}/roles`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_ROLES_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_ROLES_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    });
}


export const getCompanyDetailsAuthorized: TAction = (payload: any) => (dispatch: any, getState: any) => {

    return api.get(`/companies/${getState().companies?.company?._id}/details`).then((response: any) => {

    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        return errorResponse;
    })

}


export const createCustomCDNEndpoint: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.CREATE_CUSTOM_CDN_ENDPOINT,
        payload
    })
    return api.post(`/companies/${getState()?.companies?.currentCompany}/cdn-endpoints`, payload).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.CREATE_CUSTOM_CDN_ENDPOINT_SUCCESSFUL,
            payload: response.data
        })
        toast.success("CDN Endpoint created successfully");
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        dispatch({
            type: ActionDispatchTypes.CREATE_CUSTOM_CDN_ENDPOINT_FAILED,
            payload: errorResponse
        });
        toast.error("CDN Endpoint is not active, please try again later");
        return errorResponse;
    })
}