import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';

import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import api from '@clients/api.client';
import { apiLoginInstance } from '@clients/api.client';

import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";

import { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import { toggleGenericSuccessModal } from '@redux/utils/components/GenerateSuccessModal';
import { toast } from 'react-toastify';
import * as Cookie from "@utils/cookies";
import moment from 'moment';
import { boolean } from 'mathjs';


/* Login a user */
export const login: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.LOGIN,
        payload: payload
    });
    apiLoginInstance.post('/login', payload).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        //Cookie.setItem('token', response?.data?.authorization?.token);
        console.log('first response', response);
        dispatch({
            type: ActionDispatchTypes.LOGIN_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        console.log('errorResponse', errorResponse)
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.LOGIN_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse
    })
}

/* Lockout a user */
export const lock: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.LOCK,
        payload: payload
    });
}

/* Login a user */
export const reLogin: TAction = (payload: {
    password: string,
    email: string,
    recaptchaValue: string
}) => async (dispatch, getState) => {
    const body = {
        email: payload?.email,
        password: payload.password,
        recaptcha: payload.recaptchaValue
    };
    dispatch({
        type: ActionDispatchTypes.LOGIN,
        payload: body
    });
    return apiLoginInstance.post('/login', body).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.LOGIN_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.LOGIN_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
    })
}


/* Register a user */
export const register: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.REGISTER,
        payload: payload
    }); // Sends register started
    return apiLoginInstance.post('/register', payload).then(response => {
        dispatch({
            type: ActionDispatchTypes.REGISTER_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer

        if (response?.data?.node?.isInvited) {
            console.log('response?.data?.node?.isInvited', response?.data?.node?.isInvited)
            /* Check Url is not  company/users/invites */
            if (window.location.href.indexOf('company/users/invites') === -1) {
                window.location.href = `/company/users/invites/${response?.data?.node?.isInvited}`;
            }

        }
    }).catch((error) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        dispatch({
            type: ActionDispatchTypes.REGISTER_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
    })
}


/* Get Logged User's Data */
export const getCurrentUser: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.GET_CURRENT_USER,
        payload: payload
    });
    return api.get('/users/me').then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.GET_CURRENT_USER_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer


        if (response?.data?.node?.isInvited) {
            console.log('response?.data?.node?.isInvited', response?.data?.node?.isInvited)
            /* Check Url is not  company/users/invites */
            if (window.location.href.indexOf('company/users/invites') === -1) {
                window.location.href = `/company/users/invites/${response?.data?.node?.isInvited}`;
            }

        }

        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        if (error?.response?.status === 401 && !errorResponse) {
            dispatch({
                type: ActionDispatchTypes.LOGOUT,
                payload: null
            })
            window.location.href = "/";
        }
        if (errorResponse?.code === "USER_NOT_FOUND") {
            dispatch({
                type: ActionDispatchTypes.LOGOUT,
                payload: null
            })
            window.location.href = "/";
        }
        dispatch({
            type: ActionDispatchTypes.GET_CURRENT_USER_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return error;
    })
}


/* Get Logged User's Data */
export const editUser: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.UPDATE_USER,
        payload: payload
    });
    return api.put('/users/me', payload).then((response: any) => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.UPDATE_USER_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPDATE_USER_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return error;
    })
}

/* Get Logged User's Data */
export const deleteUser: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.DELETE_USER,
        payload: payload
    });
    return api.delete('/users/me', payload).then((response: any) => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.DELETE_USER_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_USER_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return error;
    })
}


/* Logout a user */
export const logoutUser: TAction = () => async (dispatch, getState) => {

    api.get('/logout').then((response: any) => {
        console.log('LOGOUT RESPONSE', response)
    }).catch((error: any) => {
        console.log('LOGOUT ERROR', error)
    });

    dispatch({
        type: ActionDispatchTypes.LOGOUT,
        payload: null
    });
}

const redirectSuccessfulConfirmation = (to: any) => {
    window.location.href = `/mail/confirmation/successful/?to=${to || ''}`;
}
const redirectFailedConfirmation = () => {
    window.location.href = '/mail/confirmation/failed';
}


export const forgetPassword: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.FORGET_PASSWORD,
        payload: payload
    })

    return apiLoginInstance.post('/users/password/forget-password', payload).then((response: any) => {
        toast.success(response.data?.response?.message)
        dispatch({
            type: ActionDispatchTypes.FORGET_PASSWORD_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse.message)
        dispatch({
            type: ActionDispatchTypes.FORGET_PASSWORD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return error;
    })
}

export const checkForgetPassword: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.CHECK_RESET_PASSWORD,
        payload: payload
    })
    return apiLoginInstance.get('/users/reset-password/check', {
        params: payload
    }).then((response: any) => {
        //toast.success(response.data?.response?.message)
        dispatch({
            type: ActionDispatchTypes.CHECK_RESET_PASSWORD_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message)
        dispatch({
            type: ActionDispatchTypes.CHECK_RESET_PASSWORD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const changePassword: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.RESET_PASSWORD,
        payload: payload
    })
    return apiLoginInstance.post('/users/reset-password/update', payload).then((response: any) => {
        toast.success(response.data?.response?.message)
        dispatch({
            type: ActionDispatchTypes.RESET_PASSWORD_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message)
        dispatch({
            type: ActionDispatchTypes.RESET_PASSWORD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}


export const confirmMail: TAction = (payload: {
    userId: string,
    emailVerificationCode: string,
    to: string
}) => async (dispatch, getState) => {
    /* Starts confirm mail action */
    dispatch({
        type: ActionDispatchTypes.CONFIRM_MAIL,
        payload: payload
    });
    /* Send the request to the server */
    return apiLoginInstance.post(`/users/${payload?.userId}/confirm-mail/${payload?.emailVerificationCode}`, payload).then(response => {
        dispatch({
            type: ActionDispatchTypes.CONFIRM_MAIL_SUCCESSFUL,
            payload: response.data
        }); // Sends the error response to the reducer
        redirectSuccessfulConfirmation(payload?.to);
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        redirectFailedConfirmation();
        dispatch({
            type: ActionDispatchTypes.CONFIRM_MAIL_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return error;
    })
}


type TAddUserToCompany = {
    invites: {
        email: string,
        role: string
    }[],
    company: string
}

export const addUserToCompany: TAction = (payload: TAddUserToCompany) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.CREATE_ADD_USER_TO_COMPANY,
        payload: payload
    })
    const company = getState()?.companies?.company?._id;
    return api.post(`/companies/${company}/invites`, payload).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.TOGGLE_ADD_USER_TO_COMPANY_MODAL,
            payload: false
        })
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.CREATE_ADD_USER_TO_COMPANY_SUCCESSFUL,
            payload: response.data
        }); // Sends the error response to the reducer
        return response.data;
    }).catch((err: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(err);
        //toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.CREATE_ADD_USER_TO_COMPANY_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return err;
    })

}

export const getUserInvite: TAction = (payload: any) => async (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.GET_COMPANY_USER_INVITE,
        payload: payload
    })

    return api.get(`/users/invites/${payload?.inviteCode}`).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_USER_INVITE_SUCCESSFUL,
            payload: response.data
        }); // Sends the error response to the reducer
        return response.data;
    }).catch((err: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(err);
        //toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_USER_INVITE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return err;
    })

}

export const acceptUserInvite: TAction = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE,
        payload: payload
    })
    return api.post(`/users/invites/${payload?.inviteId}/accept`, payload).then((response: any) => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        window.location.href = "/"
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE_SUCCESSFUL,
            payload: response.data
        }); // Sends the error response to the reducer
        return response.data;
    }).catch((err: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(err);
        //toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}
export const acceptUserInviteByInviteCode: TAction = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE,
        payload: payload
    })
    return api.get(`/users/invites/${payload?.inviteCode}/accept`).then((response: any) => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        window.location.href = "/"
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE_SUCCESSFUL,
            payload: response.data
        }); // Sends the error response to the reducer
        return response.data;
    }).catch((err: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(err);
        //toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}


export const declineUserInvite: TAction = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.DECLINE_COMPANY_USER_INVITE,
        payload: payload
    })
    return api.post(`/users/invites/${payload?.inviteId}/decline`, payload).then((response: any) => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        toast.success(response.data.response?.message);
        dispatch({
            type: ActionDispatchTypes.DECLINE_COMPANY_USER_INVITE_SUCCESSFUL,
            payload: response.data
        }); // Sends the error response to the reducer
        return response.data;
    }).catch((err: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(err);
        //toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.DECLINE_COMPANY_USER_INVITE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const changeUsersLocaleWithoutRequest: any = (payload: any) => (dispatch: any) => {
    dispatch({
        type: ActionDispatchTypes.CHANGE_USERS_LOCALE,
        payload: {
            locale: payload
        }
    })
}

export const changeUsersLocale: TAction = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.CHANGE_USERS_LOCALE,
        payload: payload
    })

    if (!getState()?.users?.user?._id) {
        return;
    }

    return api.post(`/users/update/locale`, payload).then((response: any) => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        toast.success(response.data.response?.message);
        moment.locale(response.data?.node?.user?.locale);
        window.location.reload();
        dispatch({
            type: ActionDispatchTypes.CHANGE_USERS_LOCALE_SUCCESSFUL,
            payload: response.data
        }); // Sends the error response to the reducer
        return response.data;
    }).catch((err: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(err);
        //toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.CHANGE_USERS_LOCALE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}