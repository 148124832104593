import Environment from "@clients/index.env";
import { urlFriendly } from "@utils/url";
export const redirectToMainApp: () => void = () => {
    const host: string = window.location.host;
    if (Environment?.PRODUCTION) {
        if (host !== "app.jetlexa.com") {
            window.location.href = `https://app.jetlexa.com` + window.location.pathname;
        }
    } else if (Environment?.STAGING) {
        if (host !== "staging.jetlexa.com") {
            window.location.href = `https://staging.jetlexa.com` + window.location.pathname;
        }
    } else if (Environment?.DEVELOPMENT) {
        if (host !== "localhost:3000") {
            window.location.href = `http://localhost:3000` + window.location.pathname;
        }
    } else {
        if (host !== "app.jetlexa.com") {
            window.location.href = `https://app.jetlexa.com` + window.location.pathname;
        }
    }



}

export const redirectToCompanySubdomain = (data: {
    companyTitle: string;
    token: string | null;
    refresh_token: string | null;
}): void => {
    const subdomain = urlFriendly(data.companyTitle);
    if (window.location.hostname.split(".")[0] !== subdomain) {
        if (data.token && data.refresh_token) {
            if (!Environment.STAGING) {
                const domain = Environment.DEVELOPMENT
                    ? "localhost:3000"
                    : "jetlexa.com";

                const protocol = Environment.DEVELOPMENT
                    ? "http" : "https";
                //localStorage.removeItem("users::token");
                const url = new URL(window.location.href);
                url.searchParams.append("refresh_token", data.refresh_token);
                url.searchParams.append("token", data.token);
                console.log('URL SEARCH', url.searchParams.toString());
                window.location.href = protocol + "://" + subdomain + "." + domain + url.pathname + "?" + url.searchParams;
            }
        }
    }
}